.logo-img{
    margin-top:0.70rem;
    width:6rem;
    border-radius: 20rem;
    box-shadow: 0 .5rem 1rem rgba(11, 11, 11, 3);
}
.ul-Home{
  list-style: none;
  margin-top: -4rem;
  color: #fcf9f9;
  margin-left: -2rem;
}
.ul-Home li{
  display: inline-block;
  margin: 0 10px;
  margin-top: -5rem;
  
}
.ul-Home li a{
  text-decoration: none;
  color: #fcfafa;
  font-weight: 500;
  font-size: 15px;
  text-transform: uppercase;
  margin-top: -6rem;
 
}
.ul-Home li a::after{
  content: "";
  width: 0;
  height: 2px;
  background-color: #fb2077;
  display:block;
  transition: 0.2s linear;
}
.ul-Home li a:hover::after{
 width: 100%;
}
.snip1193 h1{
  color: #fbd17d;
  font-size: 3rem;
  margin-top: -0.50rem;
}

@import url(https://fonts.googleapis.com/css?family=Raleway:400);
@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
figure.snip1193 {
  font-family: 'Raleway', Arial, sans-serif;
  color: #fff;
  position: relative;
  overflow: hidden;
  margin: 10px;
  min-width: 220px;
  max-width: 310px;
  max-height: 220px;
  width: 100%;
  background: #000000;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  margin-left:4rem;
  margin-top: 3rem;
}
figure.snip1193 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
}
figure.snip1193 img {
  opacity: 1;
  width: 100%;
  display: block;
}
figure.snip1193 figcaption {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 10px 12px 5px;
  position: absolute;
}
figure.snip1193 h4,
figure.snip1193 i {
  color: #ffffff;
}
figure.snip1193 h4 {
  font-weight: 400;
  left: 0;
  width: 100%;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
}
figure.snip1193 i {
  font-size: 22px;
}
figure.snip1193 a {
  text-decoration: none;
}
figure.snip1193 .bottom-left,
figure.snip1193 .bottom-right {
  position: absolute;
  bottom: 5px;
  opacity: 0;
}
figure.snip1193 .bottom-left {
  left: 5px;
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}
figure.snip1193 .bottom-right {
  right: 5px;
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
}
figure.snip1193:hover img,
figure.snip1193.hover img {
  opacity: 0.6;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
figure.snip1193:hover figcaption .bottom-left,
figure.snip1193.hover figcaption .bottom-left,
figure.snip1193:hover figcaption .bottom-right,
figure.snip1193.hover figcaption .bottom-right {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  opacity: 1;
}

.project-wood{
  background-image: url('../src/assets/project-wood.jpg');
 width: 50rem;
 height:120rem;
 
}
.social-contact{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(28em, 1fr));
  gap:1rem;
  margin-left:13rem;
  margin-top:5rem;
}
.project-aodot{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50rem, 1fr));
  gap:1rem;
}
.email-contact{
  color: #fffffe;
  margin-left:16rem;
  margin-top:5rem;
  font-size:1rem;
}
.email-contact h2{
  font-family: fangsong ;
}
.instagram-contact{
  margin-left: 13rem;
  width:4rem;
}
.tiktok-contact{
  margin-left: 13rem;
  width:4rem;
}
.facebook-contact{
  margin-left: 13rem;
  width:4rem;
}

.whatsapp-contact{
  margin-left: 13rem;
  margin-top: 10rem;
  width:4rem;
  margin-top: -0.20rem;
}
.grafy{
 
  font-size: 1.30rem;
  margin-left: 2rem;
}
.kitchen-wood{
  width:100vw;
  height:25rem;
  margin-top: 2rem;
}
.button-input{
  width:8rem;
  height:2rem;
  background-color: #3c2610;
  margin-top:2rem;
  margin-left:11rem;
}
.button-input h2{
  margin-top: 0.40rem;
  color: #fbfbfa;
  font-size: 1rem;
}
.abouts input{
  width:12rem;
  height: 2rem;
}
.style-input{
  display: grid;
  margin-left:9rem;
  gap:1rem;
  margin-top: 2rem;
}
.style-input input{
  box-shadow: 0 .5rem 1rem rgba(10, 10, 10, 5);
}

.carpentry-profile{
  font-size:1.10rem;
  margin-left:1.50rem;
  margin-top: 4rem;
}


.wood-button{
  border-radius: 10rem;
  font-size:0.80rem;
  background-color:rgb(249, 212, 160);
  color:#0a090a;
  margin-left:12rem;
  border:.1rem solid #925d28;
  margin-top:5rem ;
}
.wood-button h2{
  color: #925d28 ;
  font-family: ui-serif;
  font-size:1.70rem;
  
}
.wood-tits{
  margin-left:10rem;
  margin-top: 3rem;
  color: #f4d98f;
}
.wood-tit{
  margin-left:10rem;
  margin-top:-2rem;
  font-size:2.90rem;
  color: #f7d482;
}

.img-woods{
 background-color: #f8e4ad;
}
.img-kitchen{
  width:22rem;
  margin-left: 3rem;
  margin-top:18rem;
}

.wood-img{
    background-image:url("../src/assets/wood-ashraf.jpeg") ;
     background-repeat: no-repeat;
    background-size: contain; 
    width:45rem;
   
}
.room-4{
  background-image: url("../src/assets/room2.jpg");
  background-repeat: no-repeat;
  /* background-size: contain; */
  width:100vw; 
  margin-top: -16rem;
}
.room-3{
  background-image: url("../src/assets/room2.jpg");
  background-repeat: no-repeat;
  /* background-size: contain; */
  width:100vw; 
  margin-top: -16rem;
}
.room-5{
  background-image: url("../src/assets/room2.jpg");
  background-repeat: no-repeat;
  /* background-size: contain; */
  width:100vw; 
  margin-top: -16rem;
}
.rooms-2{
  background-image: url("../src/assets/room2.jpg");
  background-repeat: no-repeat;
  /* background-size: contain; */
  width:100vw; 
  margin-top: -16rem;
}
.rooms{
  background-image: url("../src/assets/rooms.jpg");
  background-repeat: no-repeat;
  /* background-size: contain; */
  width:100vw; 
  margin-top: -15rem;
}
.img-contact{
  background-image: url("../src/assets/img-contact.jpeg");
  height:60rem;
  width:50rem;
  margin-top:-7rem;
  background-repeat: no-repeat;
  background-size: contain; 
  margin-left: -4.60rem;

}
.title-contact{
  color: #fbfbfa;
  font-size:5rem;
  margin-left: 10rem;
  font-family: fangsong ;
  margin-top:7rem;
}
.e-contact{
  font-size:1.20rem;
  color: #fafcfc;
  margin-left:14rem;
  font-family: fangsong ;
}

  
  .btn{
    background-color: rgb(251, 127, 19);
    position: relative;
    left: 37rem;
    bottom: 10rem;
    width: 10rem;
    cursor: pointer;
    box-shadow: 0 .5rem 1rem rgba(11, 11, 15, 3);
    padding: 4px 4px;
    height: 2rem;
    margin-top: 14rem;
  }
  .btn a{
    color: #fbfafa;
    text-decoration: none;
    margin-left: 1.20rem;
    font-size: 1.50rem
  }
  .search{
    background-color: #fafcfc;
    width: 20rem;
    height: 2rem;
    margin-left: 40rem;
    margin-top: -2.80rem;
  }
  .search-icon{
    margin-left:57.50rem;
    margin-top: -3rem;
    width:3rem;
  }
  .hover-image{
    display: none;
    position: relative;
    width: 100%;
    transform: translateX(-50%);
  }
  .title:hover.hover-image{
    display: block;
  }
  .search input{
    width:19.70rem;
    height: 2rem;
  }
  .title{
    width: 20rem;
    height:17rem;
    margin-top: -30rem;
    margin-left:5rem;
  }
  .title h2{
    margin-left: 2rem;
    color: #fbfafa;
    transition:color 0.3 ease ;
  }
  .title:hover h2{
    color: #f1c359;
  }
  .wood-con{
    margin-top:5rem;
    width:100vw;
    height:27rem;
  }
  .title h3{
    margin-left: 2rem;
    color: #fcf9f9;
  }
  .room-bath-a{
    background-color: #fbfbfb;
    width:7rem;
    margin-left:14rem;
    
  }
  .title h5{
    margin-left: 2rem;
    font-size: 1rem;
  }
  
  .room-logo{
    font-size:4rem;
    margin-left:4rem;
    font-family: fangsong ;
    color: #f7d482;
  }
  .room-logos{
    font-size:5rem;
    margin-left:4rem;
    font-family: fangsong ;
    color: #fdfcfb;
  }
  .email button{
    border: #030303;

  }
  .products-container{
    background-color: #060606;
    margin-top: -3rem;
  }
  .project-a a{
   color: #fafcfc;
  }
  .title{
    text-align: center;
    position: relative;
  }
  title h2{
    color: #925d28;
  }
  .products-containerRoomBath{
    background-color: #1b1101;
    margin-top: -3rem;
  }
  .products-roomSalon{
    background-color: #422b09;
    margin-top: -3rem;
  }
  .products-container-room{
    background-color: #4a2d11;
    margin-top: -3rem;
  }
  .roomSalon-logo{
    font-size:5rem;
    margin-left:7rem;
    font-family: fangsong ;
    color: #f1c359;
  }
  .products-roomCloset{
    background-color: #925d28;
    margin-top: -3rem;
  }
  .img-BadRoom{
    width:22rem;
    margin-left: 3rem;
    margin-top:18rem;
  }
  .img-about{
    width:25rem;
    margin-left:2rem;
    margin-top: 5rem;
  }
  .image-wood-about{
    width:25rem;
    margin-left:1rem;
  }
  .title img{
    width: 2rem;
    margin-left:2rem;
  }
  .note{
    background-color: rgb(249, 203, 130);
    width:80vw;
    margin-top:36rem;
    margin-left:2rem;
    height:25rem;
    box-shadow: 2rem 1rem rgb(10, 14, 1);

  }
  .note h2{
    margin-left:2rem;
    margin-top: -28rem;
    font-size:1.30rem;
  }
  .phone{
    margin-top: -2rem;
    font-size:1.70rem;
  }
  .header-project a{
    color: #3d300c;
  }
  .about-project{
    margin-top: -5.60rem;
  }
  .about-project1{
    margin-top:-1rem;
   
  }
  .wood-project{
    width:35rem;
    height:60rem;
  }
  .logo-imgs{
    margin-top:3rem;
    margin-left: 21rem;
    width:5rem;
    border-radius:10rem;
    box-shadow: 0 .5rem 1rem rgba(11, 11, 11, 3);
  }
  .project h2{
    color: #fafcfc;
    margin-top: -7rem;
    margin-left:12rem;
  }
  .header-project{
    background-color: #4a3310;
    width:51rem;
    height:9rem;
    margin-top:-2rem;
  }
  .header-project h1{
    color: #fefefe;
    margin-left: 5rem;
  }
  .yourHome{
    overflow-x: hidden;
  }
  .carpentry-title{
    font-size: 1rem;
    margin-left:2rem;
  }
  .carpentry-title h3{
    font-size: 1rem;
    margin-left:18rem;
  }

  .email{
   color: #fcf9f9;
   margin-left:12rem;
   margin-top: -8rem;
  }

  .div-social{
    font-size: 4rem;
    margin-left: -1rem;

  }
 
  .header-about{
    background-color: #1b1101;
    width:100vw;
    height: 15rem;
    margin-top:7rem;
  }
  .img-logo-abo{
    width:6.70rem;
    box-shadow: 2rem 1rem rgb(79, 52, 10);
    margin-top: 1rem;
    border-radius: 10rem;
    margin-left:1rem;
  }
  .tit-qual{
    margin-left:2rem;
    font-size: 1.20rem;
  }
 .logo-work{
    margin-top:7rem;
    font-size:6rem;
    margin-left:28rem;
    font-family: fangsong ;
    color: #fdb368;
 }
 .icon-menu{
    margin-left: 80rem;
    margin-top: -20rem;
 }
 .imagecontainer {
    float: left;
    width: 215px;
    height: auto;
    margin: 10px 0;
  }
  .imagecontainer img{width: 150px; margin:10px 0;}
  .imageuploader{width: 800px; height: 400px; background-color:#cbeefc;padding: 10px;}
  .img-profile{
    width:12rem;
    border-radius: 10rem;
    margin-top:2rem;
    margin-left:5rem;
  }
  .carpentryWood{
    width:25em;
    height:40rem;
    margin-left:30rem;
    margin-top:-48rem;
  }
  .profile{
   margin-left:3rem;
   font-size:4rem;
   margin-top:1rem;
   color: #050505;
  }

  .camera{
    margin-left: -5rem;
  }
.m-upload__file{
 margin-top:-7rem;
 margin-left: 2rem;
}
.name{
  margin-left:4rem;
  color: #060606;
}
.name-1{
  margin-left:4rem;
  color: #090909;
}
.social{
  margin-top:-15rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(4rem,1fr));
  gap:1rem;
  padding:1rem 60rem;
  margin-left: -30rem;
}
.instagram{
  background-color: #181102;
  margin-left: -0.50rem;
}
.facebook{
  background-color: #1b1101;
}
.tiktok{
  background-color: #1b1101;
}
.whatsapp{
  background-color: #1b1101;
}